import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import { Container, Row, Col } from 'reactstrap';

import Layout from '../../components/layouts/Layout';
import { fetchProperty } from '@a1-ict/fsbo/propertySlice';
import { selectProperty } from '@a1-ict/fsbo/propertySlice/selectors';

import { settings } from '../../settings';
import PropertyPreview from '../../components/Properties/PropertyPreview/PropertyPreview';
import SEO from '../../components/Seo';

const PropertyNotFound = () => {
  const location = useLocation();
  const propertyId = location.pathname.split('/').pop();
  const dispatch = useDispatch();
  const property = useSelector(selectProperty);

  useEffect(() => {
    if (propertyId) dispatch(fetchProperty({ id: propertyId }));
  }, []);

  return property && Object.keys(property).length > 0 ? (
    <Layout navColor="white">
      <SEO
        title={property.title}
        description={property.description}
        image={property.images && property.images.length > 0 && `/api${property.images[0].url}`}
      />
      <PropertyPreview className="margined-by-layout" property={property}></PropertyPreview>
    </Layout>
  ) : (
    <Layout navColor="white">
      <Container fluid="xl">
        <Row>
          <Col xl={12} className="my-3 ">
            <div
              style={{
                height: '300px',
                background: 'grey',
                marginTop: '100px',
              }}
              className="p-5"
            >
              Грешка! Имотът не може да бъде намерен
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default PropertyNotFound;
